import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import _ from 'lodash'
import clsx from 'clsx'
import {toast} from 'react-toastify'

import Loading from '../../../Components/Loading'
import DataTableAdvanced from '../../../Components/DataTableAdvanced'
import RightLayoutPopup from '../../../Components/RightLayoutPopup'
import PreferredRoutes from './Popups/PreferredRoutes'
import Details from './Popups/Details'
import EditCompanies from './Popups/EditCompanies'
import {PageTitle} from '../../../../_metronic/layout/core'
import Dropbox from '../../../Components/Dropbox'

import {CompanyService} from '../../../../services/AdminServices/CompanyService'
import {CommonService} from '../../../../services/FreightOperatorServices/CommonService'

import useScrollLock from '../../../../utils/useScrollLock'
import {KTSVG} from '../../../../_metronic/helpers'

import './Companies.scss'
import ShowUserList from './Popups/ShowUserList'

const Companies = () => {
  const intl = useIntl()
  const companyServ = new CompanyService()
  const commonServ = new CommonService()
  const [loading, setLoading] = useState<boolean>(false)

  const [filter, setFilter] = useState<any>(null)
  const [editCompanies, setEditCompanies] = useState<any>(null)
  const [pageMIndex, setPageMIndex] = useState<number>(0)
  const [userPageMIndex, setUserPageMIndex] = useState<number>(0)
  const [selectedCompanyId, setSelectedCompanyId] = useState<number>(-1)
  const [companies, setCompanies] = useState<any>([])
  const [companyStatuses, setCompanyStatuses] = useState<any>([])
  const [companyData, setCompanyData] = useState<any>({
    id: -1,
    companyName: '',
    type: '',
    country: '',
    status: '',
    website: '',
    taxNumber: '',
    taxOffice: '',
    billingAddress: '',
    authorizedName: '',
    authorizedEmail: '',
    authorizedPhone: '',
  })

  const [userListdata, setUserListData] = useState<any>([])
  const [transportTypeList, setTransportTypeList] = useState<any[]>([])

  const [showDetailPopup, setShowDetailPopup] = useState<boolean>(false)
  const [showEditPopup, setShowEditPopup] = useState<boolean>(false)
  const [showUsersPopup, setShowUsersPoup] = useState<boolean>(false)

  const [showFilterPanel, setShowFilterPanel] = useState<boolean>(false)
  const [isNextPage, setIsNextPage] = useState<boolean>(false)
  const [isUserNextPage, setIsUserNextPage] = useState<boolean>(false)

  const [noPreferred, setNoPreferred] = useState<boolean>(false)
  const [noVehicle, setNoVehicle] = useState<boolean>(false)
  const [noTrailer, setNoTrailer] = useState<boolean>(false)
  const [noTransporter, setNoTransporter] = useState<boolean>(false)

  const [showPreferredRoute, setShowPreferredRoute] = useState<boolean>(false)

  const [preferredRouteList, setPreferredRouteList] = useState<any>([])
  const [preferredRoutesPageMIndex, setPreferredRoutesPageMIndex] = useState<number>(0)

  const [companyList, setCompanyList] = useState<any[]>([])
  const [companyTypeSearch, setCompanyTypeSearch] = useState<string>('')
  const [companyTypeId, setCompanyTypeId] = useState<any>('')

  const [transportTypeSearch, setTransportTypeSearch] = useState<string>('')
  const [transportTypeId, setTransportTypeId] = useState<any>('')

  const [fromCityList, setFromCityList] = useState<any>([])
  const [toCityList, setToCityList] = useState<any>([])
  const [fromCountryList, setFromCountryList] = useState<any>([])
  const [toCountryList, setToCountryList] = useState<any>([])

  const [selectedFromFilterCountry, setSelectedFromFilterCountry] = useState<any>('')
  const [selectedToFilterCountry, setSelectedToFilterCountry] = useState<any>('')

  const [fromCountrySearch, setFromCountrySearch] = useState<string>('')
  const [fromCountryPageIndex, setFromCountryPageIndex] = useState<number>(0)
  const [fromCountryId, setFromCountryId] = useState<string>('')

  const [fromCitySearch, setFromCitySearch] = useState<string>('')
  const [fromCityPageIndex, setFromCityPageIndex] = useState<number>(0)
  const [fromCityId, setFromCityId] = useState<string>('')

  const [toCountrySearch, setToCountrySearch] = useState<string>('')
  const [toCountryPageIndex, setToCountryPageIndex] = useState<number>(0)
  const [toCountryId, setToCountryId] = useState<string>('')

  const [toCitySearch, setToCitySearch] = useState<string>('')
  const [toCityPageIndex, setToCityPageIndex] = useState<number>(0)
  const [toCityId, setToCityId] = useState<string>('')

  const [dependentFromCity, setDependentFromCity] = useState<boolean>(false)
  const [dependentToCity, setDependentToCity] = useState<boolean>(false)

  const [fromCountryScrollAPICalled, setFromCountryScrollAPICalled] = useState<boolean>(false)
  const [toCountryScrollAPICalled, setToCountryScrollAPICalled] = useState<boolean>(false)
  const [fromCityScrollAPICalled, setFromCityScrollAPICalled] = useState<boolean>(false)
  const [toCityScrollAPICalled, setToCityScrollAPICalled] = useState<boolean>(false)

  const {scrollUnlock} = useScrollLock()

  useEffect(() => {
    fromCountryId && getFromSearchCityList(fromCountryId, 0)
  }, [fromCountryId])

  useEffect(() => {
    toCountryId && getToSearchCityList(toCountryId, 0)
  }, [toCountryId])

  const getFromCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCountryScrollAPICalled(false)
        if (pageIndex === 0) setFromCountryList(resp?.data?.filter((item: any) => item?.name))
        else setFromCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCountryScrollAPICalled(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getFromSearchCityList = (
    countryCode: string,
    pageIndex: number = 0,
    searchText?: string
  ) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setFromCityScrollAPICalled(false)
        if (pageIndex === 0) setFromCityList(resp?.data?.filter((item: any) => item?.name))
        else setFromCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setFromCityScrollAPICalled(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToCountryList = (pageIndex: number = 0, searchText?: string) => {
    commonServ
      .countrySearchList(searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCountryScrollAPICalled(false)
        if (pageIndex === 0) setToCountryList(resp?.data?.filter((item: any) => item?.name))
        else setToCountryList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setToCountryScrollAPICalled(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getToSearchCityList = (countryCode: string, pageIndex: number = 0, searchText?: any) => {
    commonServ
      .citySearchList(countryCode, searchText ?? '', pageIndex ?? 0)
      .then((resp: any) => {
        setToCityScrollAPICalled(false)
        if (pageIndex === 0) setToCityList(resp?.data?.filter((item: any) => item?.name))
        else setToCityList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setToCityScrollAPICalled(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    if (!showDetailPopup && !showEditPopup) scrollUnlock()
  }, [showDetailPopup, showEditPopup])

  const getPreferredRouteList = (companyId?: number, pageIndex?: number) => {
    companyServ
      .getPreferredRouteList(companyId, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setPreferredRouteList(resp.data)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getUserListData = (companyId?: number, pageIndex?: number) => {
    companyServ
      .getCompanyUserList(companyId, pageIndex)
      .then((resp: any) => {
        setLoading(false)
        setIsUserNextPage(false)
        setUserListData(resp.data)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsUserNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getTransportTypeList = () => {
    companyServ
      .transportTypesList()
      .then((resp: any) => {
        setTransportTypeList(resp?.data)
      })
      .catch((e: any) => {
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
        setLoading(false)
      })
  }

  const getCompanies = (pageIndex?: number, searchText?: string) => {
    companyServ
      .getAllCompanies(
        pageIndex,
        searchText,
        noPreferred ? 'Y' : 'N',
        noVehicle ? 'Y' : 'N',
        noTrailer ? 'Y' : 'N',
        noTransporter ? 'Y' : 'N',
        companyTypeId === 'all' ? '' : companyTypeId,
        transportTypeId === 'all' ? '' : transportTypeId,
        fromCountryId === 'all' ? '' : fromCountryId,
        toCountryId === 'all' ? '' : toCountryId,
        fromCityId === 'all' ? '' : fromCityId,
        toCityId === 'all' ? '' : toCityId
      )
      .then((resp: any) => {
        setCompanies(resp.data)
        setLoading(false)
        setIsNextPage(false)
      })
      .catch((e: any) => {
        setLoading(false)
        setIsNextPage(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getCompanyStatuses = () => {
    companyServ
      .getCompanyStatutes()
      .then((resp: any) => {
        setCompanyStatuses(resp.data)
        setLoading(false)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }
  const updateCompanyStatus = (id: number, statusId: number) => {
    companyServ
      .updateCompanyStatus(id, statusId)
      .then(() => {
        toast.success(intl.formatMessage({id: 'Admin.companies.status.update.success'}))
        getCompanies(pageMIndex)
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getDetails = (id: number) => {
    return companyServ.getCompanyDetails(id)
  }
  const getInvoiceAddress = (id: number) => {
    return companyServ.getCompanyInvoiceAddress(id)
  }
  const getResponsibles = (id: number) => {
    return companyServ.getCompanyResponsibles(id)
  }

  const onPreferredRoutePaginationChange = (page: number) => {
    getPreferredRouteList(selectedCompanyId, page)
    setPreferredRoutesPageMIndex(page)
  }

  const onPaginationChange = (page: number) => {
    if (!isNextPage) {
      getCompanies(page, filter)
      setPageMIndex(page)
    }
    setIsNextPage(true)
  }

  const onPaginationUserListChange = (page: number) => {
    if (!isUserNextPage) {
      getUserListData(page)
      setUserPageMIndex(page)
    }
    setIsUserNextPage(true)
  }

  const getCompanyTypes = () => {
    commonServ
      .getCompanyTypes()
      .then((resp: any) => {
        setCompanyList(
          [
            {
              companyTypeId: 'all',
              description: intl.formatMessage({id: 'global.all'}),
            },
          ].concat(resp?.data)
        )
      })
      .catch((e: any) => {
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    setLoading(true)
    getCompanies(pageMIndex, filter)
    getTransportTypeList()
    getCompanyTypes()
    getFromCountryList()
    getToCountryList()
  }, [])

  useEffect(() => {
    getCompanies(pageMIndex, filter)
  }, [
    noPreferred,
    noVehicle,
    noTrailer,
    noTransporter,
    companyTypeId,
    transportTypeId,
    fromCountryId,
    fromCityId,
    toCountryId,
    toCityId,
  ])

  const handleFilterSearch = React.useMemo(
    () =>
      _.debounce((_event, searchText) => {
        setLoading(true)
        getCompanies(0, searchText)
      }, 1000),
    [
      noPreferred,
      noVehicle,
      noTrailer,
      noTransporter,
      companyTypeId,
      transportTypeId,
      fromCountryId,
      fromCityId,
      toCountryId,
      toCityId,
    ]
  )

  return (
    <>
      <div className={'w-100'}>
        <PageTitle breadcrumbs={[]}>
          {intl.formatMessage({id: 'SideMenu.systemOperator.companies'})}
        </PageTitle>
        {loading && <Loading />}

        <div className={`row ${showFilterPanel ? 'mb-0' : 'mb-5'}`}>
          <span className='menu-icon d-flex justify-content-end'>
            <div
              className={`btn btn-icon btn-active-light-primary btn-custom ${
                showFilterPanel ? 'menu-button-active' : ''
              }`}
              data-kt-menu-attach='parent'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='bottom'
              onClick={() => setShowFilterPanel(!showFilterPanel)}
            >
              <KTSVG path='/media/filtering.svg' className='svg-icon-2x' />
            </div>
          </span>
        </div>

        {showFilterPanel && (
          <>
            <div className='row'>
              <div className='mb-10 col-md-3 filter-wrap-content-company'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'Admin.Adverts.table_company'})}
                </h6>
                <div className={''}>
                  <input
                    placeholder={intl.formatMessage({id: 'Admin.Adverts.table_company'})}
                    className={clsx('form-control form-control-lg')}
                    onChange={(event: any) => {
                      handleFilterSearch(event, event.target.value)
                      setFilter(event.target.value)
                    }}
                    value={filter}
                    type='text'
                  />
                </div>
              </div>

              <div className='mb-10 row col-md-9 align-items-end'>
                <div className={'form-check mb-4 col-md-3 show-address-wrap'}>
                  <input
                    type='checkbox'
                    id={'adr2'}
                    onChange={() => setNoPreferred(!noPreferred)}
                    checked={noPreferred}
                    className={'form-check-input '}
                  />
                  <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                    {intl.formatMessage({id: 'Carrier.freight.noPreferred'})}
                  </label>
                </div>

                <div className={'form-check mb-4 col-md-3 show-address-wrap'}>
                  <input
                    type='checkbox'
                    id={'adr2'}
                    onChange={() => setNoVehicle(!noVehicle)}
                    checked={noVehicle}
                    className={'form-check-input '}
                  />
                  <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                    {intl.formatMessage({id: 'Carrier.freight.noVehicle'})}
                  </label>
                </div>

                <div className={'form-check mb-4 col-md-3 show-address-wrap'}>
                  <input
                    type='checkbox'
                    id={'adr2'}
                    onChange={() => setNoTrailer(!noTrailer)}
                    checked={noTrailer}
                    className={'form-check-input '}
                  />
                  <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                    {intl.formatMessage({id: 'Carrier.freight.noTrailer'})}
                  </label>
                </div>

                <div className={'form-check mb-4 col-md-3 show-address-wrap'}>
                  <input
                    type='checkbox'
                    id={'adr2'}
                    onChange={() => setNoTransporter(!noTransporter)}
                    checked={noTransporter}
                    className={'form-check-input '}
                  />
                  <label htmlFor='adr2' className={'text-muted fs-4 ml-3 form-check-label'}>
                    {intl.formatMessage({id: 'Carrier.freight.noTransporter'})}
                  </label>
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='mb-10 col-md-2'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'Admin.Companies.Detail.companyType'})}
                </h6>
                <div className={''}>
                  <Dropbox
                    hideArrow
                    customClass={'form-select'}
                    placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.SELECTFILTER'})}
                    customSearchValue={companyTypeSearch}
                    customOnSearch={(e: any) => {
                      setCompanyTypeSearch(e.target.value)
                    }}
                    setScrollCalled={() => {}}
                    onScroll={() => {}}
                    currentPageIndex={0}
                    menuList={
                      companyList &&
                      companyList
                        .filter(function ({companyTypeId}: any) {
                          // @ts-ignore
                          return !this[companyTypeId] && (this[companyTypeId] = companyTypeId)
                        }, {})
                        .map((item: any) => {
                          return {id: item?.companyTypeId, title: item?.description}
                        })
                    }
                    defaultClearItem={{
                      id: 'all',
                      title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                    }}
                    defaultSelected={{id: companyTypeId}}
                    selectedItemData={(item: any) => {
                      if (item?.id) {
                        setCompanyTypeId(item?.id)
                      }
                    }}
                  />
                </div>
              </div>

              <div className='mb-10 col-md-2'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'Freight.createAdvert.transportType'})}
                </h6>
                <div className={''}>
                  <Dropbox
                    hideArrow
                    customClass={'form-select'}
                    placeholder={intl.formatMessage({id: 'ADMIN.TRANSPORTS.SELECTFILTER'})}
                    customSearchValue={transportTypeSearch}
                    customOnSearch={(e: any) => {
                      setTransportTypeSearch(e.target.value)
                    }}
                    setScrollCalled={() => {}}
                    onScroll={() => {}}
                    currentPageIndex={0}
                    menuList={
                      transportTypeList &&
                      transportTypeList
                        .filter(function ({transportTypeId}: any) {
                          // @ts-ignore
                          return !this[transportTypeId] && (this[transportTypeId] = transportTypeId)
                        }, {})
                        .map((item: any) => {
                          return {id: item?.transportTypeId, title: item?.description}
                        })
                    }
                    defaultClearItem={{
                      id: 'all',
                      title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                    }}
                    defaultSelected={{id: transportTypeId}}
                    selectedItemData={(item: any) => {
                      if (item?.id) {
                        setTransportTypeId(item?.id)
                      }
                    }}
                  />
                </div>
              </div>

              <div className='mb-10 col-md-2'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCountry'})}
                </h6>
                <div className={''}>
                  <Dropbox
                    key={selectedFromFilterCountry}
                    hideArrow
                    customClass={'form-select'}
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.country.placeholder',
                    })}
                    customSearchValue={fromCountrySearch}
                    customOnSearch={(e: any) => {
                      setFromCountryPageIndex(0)
                      setFromCountrySearch(e.target.value)
                      getFromCountryList(0, e.target.value)
                    }}
                    onScroll={(val: any) => {
                      setFromCountryPageIndex(val)
                      getFromCountryList(val, fromCountrySearch)
                    }}
                    setScrollCalled={() => {
                      setFromCountryScrollAPICalled(true)
                    }}
                    scrollAPIResponse={fromCountryScrollAPICalled}
                    currentPageIndex={fromCountryPageIndex}
                    menuList={
                      fromCountryList &&
                      fromCountryList
                        ?.filter(function ({code}: any) {
                          // @ts-ignore
                          return !this[code] && (this[code] = code)
                        }, {})
                        ?.map((item: any) => {
                          return {id: item?.code, title: item?.translatedName}
                        })
                    }
                    defaultSelected={{id: fromCountryId, title: selectedFromFilterCountry}}
                    defaultClearItem={{
                      id: 'all',
                      title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                    }}
                    selectedItemData={(item: any) => {
                      if (item?.id) {
                        setFromCountryId(item?.id)
                        setSelectedFromFilterCountry(item?.title)
                        setDependentFromCity(true)
                        setFromCityId('')
                      }
                    }}
                  />
                </div>
              </div>

              <div className='mb-10 col-md-2'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'addNewAddressPopup.area2.fromCity'})}
                </h6>
                <div className={''}>
                  <Dropbox
                    hideArrow
                    disabled={!fromCountryId || fromCountryId === 'all'}
                    customClass={'form-select'}
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.city.placeholder',
                    })}
                    customSearchValue={fromCitySearch}
                    customOnSearch={(e: any) => {
                      setFromCityPageIndex(0)
                      setFromCitySearch(e.target.value)
                      getFromSearchCityList(fromCountryId, 0, e.target.value)
                    }}
                    onScroll={(val: any) => {
                      if (fromCountryId) {
                        setFromCityPageIndex(val)
                        getFromSearchCityList(fromCountryId, val, fromCountrySearch)
                      }
                    }}
                    setScrollCalled={() => {
                      setFromCityScrollAPICalled(true)
                    }}
                    scrollAPIResponse={fromCityScrollAPICalled}
                    currentPageIndex={fromCityPageIndex}
                    menuList={
                      fromCityList &&
                      fromCityList
                        ?.filter(function ({cityId}: any) {
                          // @ts-ignore
                          return !this[cityId] && (this[cityId] = cityId)
                        }, {})
                        ?.map((item: any) => {
                          return {id: item?.cityId, title: item?.name}
                        })
                    }
                    dependentValue={dependentFromCity}
                    defaultClearItem={{
                      id: 'all',
                      title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                    }}
                    defaultSelected={{id: fromCityId}}
                    selectedItemData={(item: any) => {
                      if (item?.id) {
                        setFromCityId(item?.id)
                        setDependentFromCity(false)
                      }
                    }}
                  />
                </div>
              </div>

              <div className='mb-10 col-md-2'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'addNewAddressPopup.area2.toCountry'})}
                </h6>
                <div className={''}>
                  <Dropbox
                    key={selectedToFilterCountry}
                    hideArrow
                    customClass={'form-select'}
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.country.placeholder',
                    })}
                    customSearchValue={toCountrySearch}
                    customOnSearch={(e: any) => {
                      setToCountryPageIndex(0)
                      setToCountrySearch(e.target.value)
                      getToCountryList(0, e.target.value)
                    }}
                    onScroll={(val: any) => {
                      setToCountryPageIndex(val)
                      getToCountryList(val, toCountrySearch)
                    }}
                    setScrollCalled={() => {
                      setToCountryScrollAPICalled(true)
                    }}
                    scrollAPIResponse={toCountryScrollAPICalled}
                    defaultSelected={{id: toCountryId, title: selectedToFilterCountry}}
                    currentPageIndex={toCountryPageIndex}
                    menuList={
                      toCountryList &&
                      toCountryList
                        ?.filter(function ({code}: any) {
                          // @ts-ignore
                          return !this[code] && (this[code] = code)
                        }, {})
                        ?.map((item: any) => {
                          return {id: item?.code, title: item?.translatedName}
                        })
                    }
                    defaultClearItem={{
                      id: 'all',
                      title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                    }}
                    selectedItemData={(item: any) => {
                      if (item?.id) {
                        setToCountryId(item?.id)
                        setSelectedToFilterCountry(item?.title)
                        setDependentToCity(true)
                        setToCityId('')
                      }
                    }}
                  />
                </div>
              </div>

              <div className='mb-10 col-md-2'>
                <h6 className={'mb-3'}>
                  {intl.formatMessage({id: 'addNewAddressPopup.area2.toCity'})}
                </h6>
                <div className={''}>
                  <Dropbox
                    hideArrow
                    disabled={!toCountryId || toCountryId === 'all'}
                    customClass={'form-select'}
                    placeholder={intl.formatMessage({
                      id: 'addNewAddressPopup.area2.city.placeholder',
                    })}
                    customSearchValue={toCitySearch}
                    customOnSearch={(e: any) => {
                      setToCityPageIndex(0)
                      setToCitySearch(e.target.value)
                      getToSearchCityList(toCountryId, 0, e.target.value)
                    }}
                    onScroll={(val: any) => {
                      if (toCountryId) {
                        setToCityPageIndex(val)
                        getToSearchCityList(toCountryId, val, fromCountrySearch)
                      }
                    }}
                    setScrollCalled={() => {
                      setToCityScrollAPICalled(true)
                    }}
                    scrollAPIResponse={toCityScrollAPICalled}
                    currentPageIndex={toCityPageIndex}
                    dependentValue={dependentToCity}
                    menuList={
                      toCityList &&
                      toCityList
                        ?.filter(function ({cityId}: any) {
                          // @ts-ignore
                          return !this[cityId] && (this[cityId] = cityId)
                        }, {})
                        ?.map((item: any) => {
                          return {id: item?.cityId, title: item?.name}
                        })
                    }
                    defaultClearItem={{
                      id: 'all',
                      title: intl.formatMessage({id: 'Admin.Adverts.all'}),
                    }}
                    defaultSelected={{id: toCityId}}
                    selectedItemData={(item: any) => {
                      if (item?.id) {
                        setToCityId(item?.id)
                        setDependentToCity(false)
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {!companies.length && (
          <div className='alert alert-warning mb-5 fw-bolder' role='alert'>
            {intl.formatMessage({id: 'global.noData'})}
          </div>
        )}
        <DataTableAdvanced
          columns={[
            {
              Header: 'ID',
              accessor: 'id',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-1'}),
              accessor: 'companyName',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-2'}),
              accessor: 'type',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-3'}),
              accessor: 'country',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.Table-4'}),
              accessor: 'status',
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.preferredCount'}),
              accessor: 'preferredRouteCount',
              cellStyle: {
                textAlign: 'center',
              },
              isAction: true,
              Cell: ({row}: any) => (
                <div
                  className={
                    row?.values?.preferredRouteCount !== '-' &&
                    row?.values?.preferredRouteCount !== 0
                      ? 'proposal-wrap'
                      : ''
                  }
                  onClick={() => {
                    if (
                      row?.values?.preferredRouteCount !== '-' &&
                      row?.values?.preferredRouteCount !== 0
                    ) {
                      setLoading(true)
                      setLoading(true)
                      setShowPreferredRoute(true)
                      setSelectedCompanyId(Number(row?.values?.id))
                      getPreferredRouteList(Number(row?.values?.id), preferredRoutesPageMIndex)
                    }
                  }}
                >
                  {row?.values?.preferredRouteCount}
                </div>
              ),
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.vehicleCount'}),
              accessor: 'vehicleCount',
              cellStyle: {
                textAlign: 'center',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.trailerCount'}),
              accessor: 'trailerCount',
              cellStyle: {
                textAlign: 'center',
              },
            },
            {
              Header: intl.formatMessage({id: 'Admin.Companies.transporterCount'}),
              accessor: 'transporterCount',
              cellStyle: {
                textAlign: 'center',
              },
            },
            {
              Header: 'Durum ID',
              accessor: 'statusId',
              hidden: true,
            },
            {
              Header: 'hiddenButtons',
              accessor: 'hiddenButtons',
              hidden: true,
            },
          ]}
          data={companies?.map((item: any) => {
            return {
              id: item?.companyId,
              companyName: item?.name ?? '-',
              type: item?.companyType?.description ?? '-',
              country: item?.country?.name ?? '-',
              preferredRouteCount: item?.preferredRouteCount ?? '-',
              vehicleCount: item?.vehicleCount ?? '-',
              trailerCount: item?.trailerCount ?? '-',
              transporterCount: item?.transporterCount ?? '-',
              status: item?.companyStatus?.description ?? '-',
              statusId: item?.companyStatus?.companyStatusId,
              hiddenButtons: {
                proposalBtn: false,
              },
            }
          })}
          editableRows={['']}
          editBtn={(row: any) => {
            !companyStatuses?.length && setLoading(true)
            !companyStatuses?.length && getCompanyStatuses()
            setShowEditPopup(true)
            setSelectedCompanyId(row?.values?.id)
            setEditCompanies(row?.values?.status)
          }}
          proposalBtn={(row: any) => {
            setLoading(true)
            setShowPreferredRoute(true)
            setSelectedCompanyId(Number(row?.values?.id))
            getPreferredRouteList(Number(row?.values?.id), preferredRoutesPageMIndex)
          }}
          proposalBtnText={intl.formatMessage({id: 'Admin.companies.preferredRoutes.table.title'})}
          showDetailBtn={async (row: any) => {
            let id = row?.values?.id
            if (id) {
              setLoading(true)
              await getDetails(id)
                .then((resp: any) => {
                  const det = resp.data
                  setCompanyData((item: any) => ({
                    ...item,
                    id: det?.companyId,
                    companyName: det?.name,
                    type: det?.companyType?.description,
                    country: det?.country?.name,
                    status: det?.companyStatus?.description,
                    website: det?.web,
                    taxNumber: det?.taxNumber,
                    taxOffice: det?.taxOffice,
                  }))
                })
                .catch((e: any) => {
                  setLoading(false)
                  toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
                })
              await getResponsibles(id)
                .then((resp: any) => {
                  const res = resp.data
                  setCompanyData((item: any) => ({
                    ...item,
                    authorizedName: res[0]?.name + ' ' + res[0]?.surname,
                    authorizedEmail: res[0]?.email,
                    authorizedPhone: res[0]?.mobile,
                  }))
                })
                .catch((e: any) => {
                  setLoading(false)
                  toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
                })
              await getInvoiceAddress(id)
                .then((resp: any) => {
                  const inv = resp.data
                  setCompanyData((item: any) => ({
                    ...item,
                    billingAddress: inv?.addressText,
                  }))
                })
                .catch((e: any) => {
                  setLoading(false)
                  toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
                })
              setLoading(false)
              setShowDetailPopup(true)
            }
          }}
          showFile={(row: any) => {
            setLoading(true)
            setShowUsersPoup(true)
            setSelectedCompanyId(row?.values?.id)
            getUserListData(Number(row?.values.id))
          }}
          showFileText={intl.formatMessage({id: 'Admin.company.user.showUser'})}
          setPageMIndex={onPaginationChange}
          pageMIndex={pageMIndex}
        />
      </div>

      {showPreferredRoute && (
        <PreferredRoutes
          selectedCompanyId={selectedCompanyId}
          onClose={() => setShowPreferredRoute(false)}
          preferredRouteList={preferredRouteList}
          pageMIndex={preferredRoutesPageMIndex}
          getPreferredRouteList={getPreferredRouteList}
          onPaginationChange={onPreferredRoutePaginationChange}
          setLoading={setLoading}
          getCompanies={getCompanies}
          transportTypeList={transportTypeList}
        />
      )}

      {/*DETAIL POPUP*/}
      {showDetailPopup && (
        <Details allData={companyData} onClose={() => setShowDetailPopup(false)} />
      )}

      {showUsersPopup && (
        <ShowUserList
          userList={userListdata}
          selectedCompanyId={selectedCompanyId}
          getUserListData={getUserListData}
          setLoading={setLoading}
          onPaginationChange={onPaginationUserListChange}
          pageIndex={userPageMIndex}
          onClose={() => setShowUsersPoup(false)}
        />
      )}

      {/*EDIT POPUP*/}
      {showEditPopup && (
        <RightLayoutPopup
          title={intl.formatMessage({id: 'Admin.Companies.update.title'})}
          className='admin-edit-company-wrap'
          isCloseIcon
          closeOnClick={() => setShowEditPopup(false)}
        >
          <EditCompanies
            setFilter={setEditCompanies}
            filter={editCompanies}
            companyStatuses={companyStatuses}
            setLoading={setLoading}
            updateCompanyStatus={updateCompanyStatus}
            selectedCompanyId={selectedCompanyId}
            onClose={() => setShowEditPopup(false)}
          />
        </RightLayoutPopup>
      )}
    </>
  )
}

export default Companies
