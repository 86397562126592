import {apiUrl, globalPageCount} from '../conf'
import axios from 'axios'

export class TransporterGroupService {
  getTransporterGroups({searchText, pageIndex}: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/groups?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  createTransporterGroup(description?: any, explanation?: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/transporter-management/transporters/groups',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        description: description,
        explanation: explanation,
      }),
    }

    return axios(config)
  }

  updateTransporterGroup(transporterGroupId?: any, description?: any, explanation?: any) {
    const config: any = {
      method: 'PUT',
      url: apiUrl + '/transporter-management/transporters/groups',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        transporterGroupId: transporterGroupId,
        description: description,
        explanation: explanation,
      }),
    }

    return axios(config)
  }

  deleteTransporterGroup(transporterGroupId?: any) {
    const config: any = {
      method: 'DELETE',
      url: apiUrl + `/transporter-management/transporters/groups/${transporterGroupId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getSelectedTransporterGroups(transporterGroupId: any) {
    const config: any = {
      method: 'GET',
      url: apiUrl + `/transporter-management/transporters/groups/${transporterGroupId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getSelectedTransporterGroupManagers({transporterGroupId, searchText, pageIndex}: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/groups/${transporterGroupId}/managers?searchText=${
          searchText ?? ''
        }&pageIndex=${pageIndex ?? 0}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getSelectedTransporterGroupMambers({transporterGroupId, searchText, pageIndex}: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/groups/${transporterGroupId}/members?searchText=${
          searchText ?? ''
        }&pageIndex=${pageIndex ?? 0}&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getTransporterMemberList(pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/transporters/groups/members/carriers/filter?driverId=&name=&surname=&vehicleId=&vehiclePlate=&trailerId=&trailerPlate=&companyId=&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getUserList(searchText?: any, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/user-management/users?searchText=${searchText ?? ''}&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  getTransporterListbyCompanyId(companyId?: any, pageIndex?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl +
        `/transporter-management/companies/${companyId}/transporters?&pageIndex=${
          pageIndex ?? 0
        }&pageCount=${globalPageCount}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }
    return axios(config)
  }

  createTransporterGroupManager(transporterGroupId?: any, userId?: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/transporter-management/transporters/groups/managers',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        transporterGroup: {
          transporterGroupId: transporterGroupId,
        },
        user: {
          userId: userId,
        },
      }),
    }

    return axios(config)
  }

  createTransporterGroupMamber(transporterGroupId?: any, transporterId?: any, transporterCompanyId?: any) {
    const config: any = {
      method: 'POST',
      url: apiUrl + '/transporter-management/transporters/groups/members',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        transporterGroup: {
          transporterGroupId: transporterGroupId,
        },
        transporter: {
          transporterId: transporterId,
        },
        transporterCompany: {
          companyId: transporterCompanyId
        }
      }),
    }

    return axios(config)
  }

  deleteTransporterGroupManager(transporterGroupManagerId?: any) {
    const config: any = {
      method: 'DELETE',
      url:
        apiUrl +
        `/transporter-management/transporters/groups/managers/${transporterGroupManagerId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  deleteTransporterGroupMamber(transporterGroupMamberId?: any) {
    const config: any = {
      method: 'DELETE',
      url:
        apiUrl + `/transporter-management/transporters/groups/members/${transporterGroupMamberId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getTransporterGroupMamberDetails(transporterGroupMamberId?: any) {
    const config: any = {
      method: 'GET',
      url:
        apiUrl + `/transporter-management/transporters/groups/members/${transporterGroupMamberId}`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }

  getTransporterGroupListCurrentUser() {
    const config: any = {
      method: 'GET',
      url:
        apiUrl + `/transporter-management/transporters/managers/groups?searchText=&pageIndex=0&pageCount=1000`,
      headers: {
        'Content-Type': 'application/json',
      },
    }

    return axios(config)
  }
}
