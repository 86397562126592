import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'

import RightLayoutPopup from '../../../../Components/RightLayoutPopup'
import useScrollLock from '../../../../../utils/useScrollLock'
import Dropbox from '../../../../Components/Dropbox'

import {TransporterGroupService} from '../../../../../services/CarrierServices/TransporterGroupService'
import {DocumentService} from '../../../../../services/CarrierServices/DocumentService'

const CreateTransporterGroupMamber = ({onClose, createTransporterGroupMamber, setLoading}: any) => {
  const intl = useIntl()
  const transporterGroupServ = new TransporterGroupService()
  const documentServ = new DocumentService()

  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)

  const [selectedUser, setSelectedUser] = useState<string>('')

  const [transporterList, setTransporterList] = useState<any>([])

  const [companyList, setCompanyList] = useState<any[]>([])
  const [selectedCompany, setSelectedCompany] = useState<any>(null)
  const [currentCompanyPageIndex, setCurrentCompanyPageIndex] = useState<number>(0)
  const [companyCustomSearch, setCompanyCustomSearch] = useState<string>('')

  const [userListScrollAPICalled, setUserListScrollAPICalled] = useState<boolean>(false)
  const [companyNameScrollAPICalled, setCompanyNameScrollAPICalled] = useState<boolean>(false)

  const {scrollLock} = useScrollLock()

  const handleGetCompanyList = (pageIndex?: any, searchText?: any) => {
    documentServ
      .getCompanyList(searchText, pageIndex)
      .then((resp: any) => {
        setCompanyNameScrollAPICalled(false)
        setLoading(false)
        if (pageIndex === 0)
          setCompanyList(
            [
              {
                companyId: 'all',
                name: intl.formatMessage({id: 'global.all'}),
              },
            ].concat(resp?.data.filter((item: any) => item.companyId))
          )
        else setCompanyList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setCompanyNameScrollAPICalled(false)
        setLoading(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  const getTransporterMemberList = (companyId?: any, pageIndex: number = 0) => {
    transporterGroupServ
      .getTransporterListbyCompanyId(companyId, pageIndex ?? 0)
      .then((resp: any) => {
        setUserListScrollAPICalled(false)
        if (pageIndex === 0)
          setTransporterList(resp?.data?.filter((item: any) => item?.transporterId))
        else setTransporterList((item: any) => [...item, ...resp?.data])
      })
      .catch((e: any) => {
        setUserListScrollAPICalled(false)
        toast.error(e?.response?.data?.message + ' | ' + e?.response?.data?.code)
      })
  }

  useEffect(() => {
    scrollLock()
    handleGetCompanyList(0, companyCustomSearch)
  }, [])

  return (
    <RightLayoutPopup
      className='carrier-transporter-group-wrap'
      isCloseIcon
      title={intl.formatMessage({id: 'Carrier.transporterGroup.member.title'})}
      closeOnClick={() => onClose()}
    >
      <div className='row'>
        <div className={'col-md-12'}>
          <label htmlFor='companyName' className={'text-muted fs-4 mb-2'}>
            {intl.formatMessage({id: 'Carrier.searchDocument.filter.companyName'})}
          </label>
          <Dropbox
            id={'companyName'}
            hideArrow
            customClass={'form-select dropBox-input-wrap'}
            placeholder={intl.formatMessage({
              id: 'Carrier.searchDocument.filter.companyName.placeHolder',
            })}
            customSearchValue={companyCustomSearch}
            customOnSearch={(e: any) => {
              setCurrentCompanyPageIndex(0)
              setCompanyCustomSearch(e.target.value)
              handleGetCompanyList(0, e.target.value)
            }}
            onScroll={(val: any) => {
              setCurrentCompanyPageIndex(val)
              handleGetCompanyList(val, companyCustomSearch)
            }}
            setScrollCalled={() => {
              setCompanyNameScrollAPICalled(true)
            }}
            scrollAPIResponse={companyNameScrollAPICalled}
            currentPageIndex={currentCompanyPageIndex}
            menuList={
              companyList &&
              companyList
                .filter(function ({companyId}) {
                  // @ts-ignore
                  return !this[companyId] && (this[companyId] = companyId)
                }, {})
                .map((item: any) => {
                  return {id: item?.companyId, title: item?.name}
                })
            }
            defaultClearItem={{
              id: 'all',
              title: intl.formatMessage({id: 'Admin.Adverts.all'}),
            }}
            defaultSelected={{id: selectedCompany}}
            selectedItemData={(item: any) => {
              if (item?.id) {
                // setLoading(true)
                if (item?.id !== 'all') {
                  getTransporterMemberList(item?.id)
                }
                setSelectedCompany(item?.id)
                setSelectedUser('')
              }
            }}
          />
        </div>
      </div>
      {selectedCompany && selectedCompany !== 'all' && (
        <div className='row mt-10 mb-10'>
          <div className='col-md-12'>
            <label htmlFor='transporter' className={'text-muted fs-4 mb-2'}>
              {intl.formatMessage({id: 'Carrier.transporterGroup.member.transporter'})}
            </label>
            <div className={''}>
              <Dropbox
                hideArrow
                customClass={'form-select dropBox-input-wrap'}
                placeholder={intl.formatMessage({
                  id: 'Carrier.transporterGroup.member.transporter',
                })}
                customSearchValue={''}
                customOnSearch={(e: any) => {}}
                disabledSearch
                onScroll={(val: any) => {
                  setCurrentPageIndex(val)
                  getTransporterMemberList(selectedCompany, val)
                }}
                setScrollCalled={() => {
                  setUserListScrollAPICalled(true)
                }}
                scrollAPIResponse={userListScrollAPICalled}
                currentPageIndex={currentPageIndex}
                isTemplate
                dropBoxClass={'dropBox-wrap'}
                menuClassWrap={selectedUser ? 'dropBox-menu-data-wrap' : 'dropBox-menu-wrap'}
                menuList={
                  transporterList &&
                  transporterList
                    .filter(function ({transporterId}: any) {
                      // @ts-ignore
                      return !this[transporterId] && (this[transporterId] = transporterId)
                    }, {})
                    ?.map((item: any) => {
                      return {
                        id: item?.transporterId,
                        title: (
                          <div className='d-flex flex-column'>
                            <div className='d-flex row' style={{justifyContent: 'space-evenly'}}>
                              <div className='col-md-3'>
                                <b>{intl.formatMessage({id: 'Carrier.freight.details.name'})}:</b>
                              </div>
                              <div className='col-md-3'>{item?.driver?.name}</div>
                              <div className='col-md-3'>
                                <b>
                                  {intl.formatMessage({id: 'Carrier.freight.details.surName'})}:
                                </b>
                              </div>
                              <div className='col-md-3'>{item?.driver?.surname}</div>
                            </div>
                            <div className='d-flex row' style={{justifyContent: 'space-evenly'}}>
                              <div className='col-md-3'>
                                <b>
                                  {intl.formatMessage({
                                    id: 'Carrier.transporterGroup.member.list.table.vehiclePlate',
                                  })}
                                  :
                                </b>
                              </div>
                              <div className='col-md-3'>{item?.vehicle?.plate}</div>
                              <div className='col-md-3'>
                                <b>
                                  {intl.formatMessage({
                                    id: 'Carrier.transporterGroup.member.list.table.trailerPlate',
                                  })}
                                  :
                                </b>
                              </div>
                              <div className='col-md-3'>{item?.trailer?.plate}</div>
                            </div>
                          </div>
                        ),
                      }
                    })
                }
                selectedItemData={(item: any) => {
                  if (item?.id) {
                    setSelectedUser(item?.id)
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}

      <div className='text-center mt-5 row freightPost-popup-container'>
        <div className={'col-md-6'}>
          <button
            onClick={onClose}
            type='button'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-danger w-100 mb-5 '
          >
            {intl.formatMessage({id: 'CreateNewContainerPopup.cancel'})}
          </button>
        </div>
        <div className={'col-md-6'}>
          <button
            className={'btn btn-success px-10 m-auto w-100'}
            type={'button'}
            disabled={selectedCompany === null || selectedCompany === 'all'}
            onClick={() => {
              onClose()
              setLoading(true)
              createTransporterGroupMamber(selectedUser, selectedCompany)
            }}
          >
            {intl.formatMessage({id: 'Carrier.drivers.create.create'})}
          </button>
        </div>
      </div>
    </RightLayoutPopup>
  )
}

export {CreateTransporterGroupMamber}
